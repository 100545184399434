import React, { useState, useEffect, useContext } from 'react'
import { GlobalDataContext } from "../../context/context";

import axios from 'axios';
import { MdAccountCircle, MdStar, MdStarOutline } from 'react-icons/md'
import { FaStar } from "react-icons/fa";





const baseUrl = 'https://apireviews.herokuapp.com/api/reviews'
const idCompany = "63f1130281bf26778fe94d59"


const ContentReviews = () => {

  const { rpdata } = useContext(GlobalDataContext);

  //conectando la api
  const urlPost = 'https://apireviews.herokuapp.com/api/reviews/63efba499ce24e5f9fe6b30c'
  const [reviewsDB, setReviewsDB] = useState([])

  useEffect(() => {
    const obtenerReviews = async () => {
      const response = await axios.get(baseUrl)

      // console.log(response.data)

      setReviewsDB(response.data)
    }
    obtenerReviews()
  }, [])


  //fin conectando la api

  //ingresar para el nuevo review
  const [addReviews, setAddReviews] = useState({
    Stars: '',
    Title: '',
    Review: '',
    Name: '',
  })


  // caturamos lo que ingresen en lo input
  const handleChange = e => {
    const { name, value } = e.target;
    setAddReviews(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  //estado de la valoracion del review
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0)

  const handleClick = value => {
    setCurrentValue(value)
    setAddReviews(prevState => ({
      ...prevState,
      Stars: value
    }))
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  // fin valoracion del review



  // metodo de ingresar reviews
  const peticionPost = async () => {
    await axios.post(urlPost, addReviews)
      .then(response => {
        setReviewsDB(reviewsDB.concat(response.data))

      })
  }



  return (
    <div>
      <div className='flex flex-col-reverse md:flex-row w-full pb-40'>
        <form className=' w-full md:w-[45%]' onSubmit={peticionPost}>
          <p className='capitalize'>your overall raiting</p>
          <div className='flex pb-8'>
            {stars.map((_, index) => {
              return (
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={(hoverValue || currentValue) > index ? '#FFBA5A' : '#a9a9a9'}
                  style={{
                    marginRight: 10,
                    cursor: "pointer"
                  }}
                />
              )
            })}
          </div>
          <div className='flex flex-col gap-5 pb-5'>

            <div className='inputBox'>
              <p className='capitalize pb-2'>title of your review</p>

              <input
                type='text'
                required
                onChange={handleChange}
                name="Title"
              />
              <span>summarize yout review or highilight and interesting detail</span>
            </div>

            <div className='inputBox'>
              <p className='capitalize pb-2'>your review</p>
              <textarea
                rows={3}
                cols={1}
                required
                onChange={handleChange}
                name="Review"
              />
              <span>tell people your review</span>
            </div>

            <div className='inputBox'>
              <p className='capitalize pb-2'>your name</p>
              <input
                type='text'
                required
                onChange={handleChange}
                name="Name"
              />
              <span>tell us your name</span>
            </div>
          </div>
          <button
            type='submit'
            className='bg-[#8100f2] boton my-3 one px-6 py-4 w-52 rounded-lg'>
            <span>submit your review</span>
          </button>


        </form>

        <div className='w-full md:w-[60%] self-center'>
          <div
            className='bg-center bg-cover w-4/5 ml-auto h-[400px] relative border rounded-3xl before:absolute before:w-full before:h-full before:bg-[#00000088] before:rounded-3xl'
            style={{
              backgroundImage: `url("${rpdata?.stock?.[0]}")`
            }}
          >
            <div className='relative w-full flex justify-between items-center p-5'>
              <h2 className='text-white'>Customer <br /> Reviews!</h2>
              <img
                src={rpdata?.dbPrincipal?.logo}
                alt='not found'
                className='w-[50%] h-[50%] '
              />
            </div>
            <img
              src='https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/customer%20review%20portada_Mesa%20de%20trabajo%201.png?alt=media&token=188a3a2b-5190-45e6-b7f9-b5e80191fbe2'
              alt='not found'
              className='absolute z-10 bottom-3 right-0  w-[90%]'
            />
          </div>
        </div>
      </div>



      {
        reviewsDB.map((item, index) => {
          return (
            item._id === idCompany ?
              <div key={index}>
                <div className='containerMansorry'>
                  {
                    item.reviews.map((review, index) => {
                      return (
                        <div key={index} className='w-full border px-5 py-10 rounded-md shadow-md cardMansorry h-full'>
                          <div className='flex items-center gap-3'>
                            <MdAccountCircle className='text-[50px]' />
                            <div>
                              <div className='flex'>
                                {
                                  [...new Array(5)].map((star, index) => {
                                    return index < review.Stars ? <MdStar className=' text-orange-400' /> : <MdStarOutline />
                                  })
                                }
                              </div>
                              <h3 className='capitalize font-bold text-[20px]'>{review.Name}</h3>
                              <p className='text-[14px] -mt-3'>{review.Date.substring(0, 10)}</p>
                            </div>
                          </div>
                          <div>
                            <h3 className='text-[22px]'>{review.Title}</h3>
                            <p>{review.Review}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              : null
          )

        })
      }
    </div >
  )
}

export default ContentReviews